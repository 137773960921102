<template>
  <div
    class="v4-page-type-container"
    :style="{
      height: $bowserMobile ? 'auto' : '100vh'
    }"
  >
    <ul class="fixed--type-container" v-if="links">
      <li
        class="type__item"
        v-for="(link, index) in links"
        :key="index"
        :class="{
          hoverClass: !$bowserMobile
        }"
        ref="items"
      >
        <a @click="onPorjectListJump(link)" class="item__link" v-html="format(link.name.en)"></a>
        <div class="hover-show-img-container" @click="onPorjectListJump(link)">
          <div class="mask-wrapper">
            <div class="img-container">
              <img :src="$bowserMobile ? link.src.mobile : link.src.pc" alt="" />
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      links: 'parentTypes',
    }),
    ...mapState(['$bowserMobile']),
  },
  data() {
    return {
      pageTimeout: null,
      currentIndex: -1,
    };
  },
  watch: {
    currentIndex(val) {
      this.$refs.items.forEach((item, index) => {
        if (index === val) {
          item.classList.add('onTouch');
        } else {
          item.classList.remove('onTouch');
        }
      });
    },
  },
  mounted() {
    const that = this;
    that.$nextTick(() => {
      setTimeout(() => {
        Array.from(document.querySelectorAll('.type__item')).forEach((item) => {
          item.classList.add('show');
        });
      }, 500);

      if (that.$bowserMobile) {
        that.pageTimeout = setTimeout(that.onInfAnimate, 500);
      }
    });
  },
  methods: {
    onPorjectListJump(link) {
      this.$router.push({
        path: `/projects/${encodeURI(link.name.en)}/${link.id}`,
      });
    },
    format(str) {
      return str.replace('||', '<br/>');
    },
    onTouchStart(e) {
      const obj = e.currentTarget;
      obj.classList.add('onTouch');
    },
    onTouchEnd(e) {
      const obj = e.currentTarget;
      obj.classList.remove('onTouch');
    },
    onInfAnimate() {
      const that = this;
      const { items } = that.$refs;
      that.currentIndex = that.currentIndex === items.length - 1 ? 0 : that.currentIndex + 1;
      setTimeout(that.onInfAnimate, 2500);
    },
  },
};
</script>

<style></style>
